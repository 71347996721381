<template>
	<div class="project-container">
		<div class="project-image-container"></div>
		<img class="project-image" :src="topImage" />
		<div class="project-text">
			<h2>Våre Kjøkkenløsninger</h2>
			<p>
				Kjøkken er et av de viktigste rommene i huset, et naturlig samlingspunkt for familien og et sted for hygge. 
                Kanskje er det endelig tid for å bygge det perfekte kjøkken helt fra scratch, eller er det på tide å realisere drømmen om den kjøkkenøyen du alltid har drømt om? 
                Vi i Ellingsen Byggservice står klare til å hjelpe deg med fantastiske kjøkkenløsninger fra vår samarbeidspartner Aubo. 
			</p>
			<p>
				Ta kontakt for en uforpliktende og hyggelig konsultasjon. Kontaktdetaljer finner du <a href="/kontakt">her</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['topImage'],
}
</script>

<style scoped>
.project-image {
	width: 1300px;
	max-width: 70%;
	padding-top: 2%;
}

</style>
